import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CheerleadingPage = () => (
  <Layout>
    <SEO title="Cheerleading" />
    <h1>TRYA DEVILDOG CHEER</h1>
    <p>
      TRYA offers youth cheerleading for Grades K5-6th. We strive to recruit
      positive role models to serve as our volunteer coaching staff who can
      guide our athletes not only to compete at a high level, but also to focus
      on character development. Go Devildogs! <strong>#weareTR</strong>
    </p>
    <h2>CHEERLEADING FAQ</h2>
    <h4>How old does my child have to be to cheer?</h4>
    <p>
      TRYA offers cheerleading for Grades K5-6th. Your child must be Grades K5-6th
      during the 2024-2025 school year. Cheerleaders will be
      put in squads Grade Based division cutoff dates of August 31 of the current
      year, unless otherwise requested to be on a different squad.
    </p>
    <h4>How much does it cost to play?</h4>
    <p>
      The Registration fee for the 2024 season is $200. TRYA is a non-profit
      organization and we try to keep costs as low as possible for our members.
      Registration costs will not only go toward high quality
      uniforms, field costs, insurance, and various other expenses, but will
      also eliminate the need for any individual squad fundraising.
    </p>
    <h4>What equipment will my child need?</h4>
    <p>
      The only thing cheerleaders need to provide is their own sneakers.
      Uniforms, poms, and bows will be provided by TRYA.
    </p>
    <h4>When are practices and games?</h4>
    <p>
      Practices will vary based on coach and field availability, but they
      typically begin the 1st week of August and are 2 days a week.
    </p>
    <p>
      Game schedules are set by FYFL and are released in late August.
      Typically, the first game is in early September. Games with FYFL are mostly
      played on Saturday. There is a gate fee for game entry. $5 per person. Coaches,
      Athletes, and 12 and under are free. Please note these days and
      times may change year to year, this is only a guideline. The last playoff
      game is usually in November.
    </p>
    <h4>Will my child have to do any fundraisers?</h4>
    <p>
      We have factored in any additional expenses to our registration costs
      in order to eliminate the need for any individual team fundraisers.
    </p>
    <h4>Who do I contact if I have a problem?</h4>
    <p>
      Any issues related to your squad should first be addressed with the coach.
      If you need additional assistance, or have a general question, please
      contact us at{" "}
      <a href="mailto:info.tryasc@gmail.com">info.tryasc@gmail.com</a> or send
      us a message on our{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/groups/345642595590502/"
      >
        facebook page
      </a>
      .
    </p>
    <h4>How can I volunteer?</h4>
    <p>
      We are always looking for motivated volunteers! Please contact us at 
      <a href="mailto:info.tryasc@gmail.com">info.tryasc@gmail.com</a> if you
      are interested in a volunteer opportunity. Thank you for your interest!
    </p>
  </Layout>
)

export default CheerleadingPage
